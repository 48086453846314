import { useRouter } from "next/router";
import { useEffect } from "react";

import { routes } from "~/routes";

import type { ExtendedNextPage } from "~/types/nextjs/extended-next-page";

const Index: ExtendedNextPage = () => {
    const { push, isReady } = useRouter();

    useEffect(() => {
        if (isReady) {
            push(routes.auth.login.getUrl());
        }
    }, [push, isReady]);

    return <></>;
};
export default Index;
