import { Route } from "./__internals__/route";

interface Routes {
    [index: string]: Route | Routes;
}

const routes = {
    auth: {
        login: { getUrl: () => "/a/auth/login" },
        checkYourEmail: { getUrl: () => "/a/auth/check-your-email" },
    },

    dashboard: {
        vouchers: {
            getUrl: (
                params: {
                    page?: number;
                    limit?: number;
                } = {},
            ) => {
                const queryParams = new URLSearchParams();

                if (typeof params.limit != "undefined") {
                    queryParams.set("limit", params.limit.toString());
                }
                if (typeof params.page != "undefined") {
                    queryParams.set("page", params.page.toString());
                }

                return `/voucher-a?${queryParams.toString()}`;
            },
        },
        keyValueEditor: {
            getUrl: () => `/a/dashboard/key-value-editor`,
        },
        employees: {
            getUrl: () => `/a/dashboard/employees`,
        },
        taskEditor: {
            getUrl: () => `/a/dashboard/photobooth-game-editor`,
        },
    },
} as const satisfies Routes;

export { routes };
export type { Route };
